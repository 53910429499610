import myAxios from "./index";
import { HeatMapDimensionMap } from '../constants';

export async function getType() {
  return new Promise(resolve => {
    myAxios.get("/person_types?image_anchor=bottom-center&image_width=22&image_height=25")
      .then(function (res) {
        if(res.data.code === 200){
          resolve(res.data.data);
        } 
      });
  })
}

export async function getPosition() {
  return new Promise(resolve => {
    myAxios.get("/person_posts?image_anchor=bottom-center&image_width=22&image_height=25")
      .then(function (res) {
        if(res.data.code === 200){
          resolve(res.data.data);
        } 
      });
  })
}

export async function getDepartment() {
  return new Promise(resolve => {
    myAxios.get("/departments?image_anchor=bottom-center&image_width=22&image_height=25")
      .then(function (res) {
        if(res.data.code === 200){
          resolve(res.data.data);
        } 
      });
  })
}

export async function getOrgs() {
  return new Promise(resolve => {
    myAxios.get("/person_orgs?image_anchor=bottom-center&image_width=22&image_height=25")
      .then(function (res) {
        if(res.data.code === 200){
          resolve(res.data.data);
        } 
      });
  })
}

export async function getDigitalFences() {
  return new Promise(resolve => {
    myAxios.get("/digital_fences")
      .then(function (res) {
        if(res.data.code === 200){
          resolve(res.data.data);
        } 
      });
  })
}

export async function getWarningList() {
  return new Promise(resolve => {
    myAxios.get("/events?page=1&pagesize=10")
      .then(function (res) {
        if(res.data.code === 200){
          resolve(res.data.data.items);
        } 
      });
  })
}

// 新增区域的接口
export async function postRegion(payload) {
  return new Promise((resolve, reject) => {
    myAxios.post("/locations", payload)
      .then(function (res) {
        if(res.data.code === 200){
          resolve();
        } else {
          reject();
        }
      });
  })
}

// 编辑区域的接口
export async function putRegion(payload) {
  let { id, name, path } = payload;
  return new Promise((resolve, reject) => {
    myAxios.put("/location/" + id, {
      name,
      path
    })
    .then(function (res) {
      if(res.data.code === 200){
        resolve();
      } else {
        reject();
      }
    });
  })
}


// export async function getRegions() {
//   return new Promise((resolve, reject) => {
//     myAxios.get("/locations") 
//       .then(function (res) {
//         if(res.data.code === 200){
//           resolve(res.data.data.items);
//         } else {
//           reject();
//         }
//       });
//   })
// }

// 获取区域底图
export async function getRegions() {
  return new Promise((resolve, reject) => {
    myAxios.get("/locations") 
      .then(function (res) {
        if(res.data.code === 200){
          resolve(res.data.data.items);
        } else {
          reject();
        }
      });
  })
}


// 返回热力图数据接口， 参数dimension，可选值type, post, depart
export async function getHeatmapData(dimension='all') {
  const dimFormatted = HeatMapDimensionMap[dimension] || dimension;
  return new Promise(resolve => {
    myAxios.get("/heatmap?dimension=" + dimFormatted)
      .then(function (res) {
        if(res.data.code === 200){
          resolve(res.data.data);
        } 
      });
  })
}

// 获取人员信息
export async function getPersonDetail(id) {
  return new Promise((resolve, reject) => {
    myAxios.get("/person/" + id) 
      .then(function (res) {
        if(res.data.code === 200){
          resolve(res.data.data);
        } else {
          reject();
        }
      });
  })
}